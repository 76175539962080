import { render, staticRenderFns } from "./Classrooms.vue?vue&type=template&id=348579dc&scoped=true&"
import script from "./Classrooms.vue?vue&type=script&lang=js&"
export * from "./Classrooms.vue?vue&type=script&lang=js&"
import style0 from "./Classrooms.vue?vue&type=style&index=0&id=348579dc&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "348579dc",
  null
  
)

export default component.exports