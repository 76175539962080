<template>
	<div>
		<v-sheet
			v-ripple
			:color="tileColor"
			:elevation="active ? 5 : 0"
			:height="$isMobileOnly || $route.params.classroomId ? 46 : 150"
			:width="$isMobileOnly || $route.params.classroomId ? '100%' : 120"
			rounded
			style="position: relative; cursor: pointer"
			@click="goToClassroom(classroomData.id)"
		>
			<v-menu offset-y rounded="lg" z-index="999">
				<template v-slot:activator="{ on, attrs }">
					<v-btn
						v-bind="attrs"
						v-on="on"
						absolute
						depressed
						icon
						plain
						height="28px"
						:ripple="false"
						:style="
							$isMobileOnly || $route.params.classroomId
								? 'right: 4px; top: 50%; transform: translateY(-50%)'
								: 'right: 4px; top: -2px;'
						"
						width="28px"
						@touchstart.stop=""
						@mousedown.stop=""
					>
						<v-icon color="white">
							{{
								$isMobileOnly || $route.params.classroomId
									? "mdi-dots-vertical"
									: "mdi-dots-horizontal"
							}}
						</v-icon>
					</v-btn>
				</template>
				<v-list class="pa-0">
					<v-list-item
						v-if="isGrantedAccess(option.roleAction, 'classroom')"
						v-for="(option, index) in options"
						:key="`${classroomData.id}-option-${index}`"
						:color="option.color"
						:style="`background-color: ${option.bgColor}`"
						class="px-2"
						link
						style="min-height: 40px"
						@click="calculateAction(option.action, classroomData.id)"
					>
						<v-list-item-title class="d-flex align-center">
							<v-icon :color="option.textColor" class="mr-2">{{
								option.icon
							}}</v-icon>
							<span :style="`color: ${option.textColor}`">{{
								option.title
							}}</span>
						</v-list-item-title>
					</v-list-item>
				</v-list>
			</v-menu>

			<div class="class-info">
				<div
					class="content"
					:style="
						$isMobileOnly || $route.params.classroomId
							? 'width: 90%'
							: 'width: 100%'
					"
					v-html="formatClassInfo(classroomData)"
				></div>
			</div>
		</v-sheet>

		<!-- Add to classroom -->
		<Dialog
			v-model="state.addUsersDialog"
			:persistent="true"
			width="900px"
			@closeDialog="resetData"
		>
			<template v-slot:header> Shto nxënës/mësues </template>

			<template v-slot:content>
				<v-form ref="addUsersForm" @submit.prevent="">
					<v-row>
						<!-- Add teachers autocomplete -->
						<v-col cols="12">
							<AddUsers
								:maxUsers="6"
								:users.sync="state.classroomData.teachers"
								role="teacher"
							/>
						</v-col>

						<!-- Add students autocomplete -->
						<v-col cols="12">
							<AddUsers
								:maxUsers="25"
								:users.sync="state.classroomData.students"
								role="student"
							/>
						</v-col>
					</v-row>
				</v-form>
			</template>

			<template v-slot:actions>
				<v-spacer></v-spacer>
				<v-btn
					:class="{ 'full-width': $isMobileOnly }"
					:disabled="state.loading || !state.hasChanges"
					:loading="state.loading"
					color="primary"
					@click="log"
				>
					Ruaj
				</v-btn>
			</template>
		</Dialog>

		<!-- Leave classroom -->
		<LeaveClassroom
			v-model="state.leaveClassroomDialog"
			:classroom-id="classroomData.id"
		/>

		<!-- Delete classroom -->
		<DeleteClassroom
			v-model="state.deleteClassroomDialog"
			:classroom-id="classroomData.id"
		/>
	</div>
</template>

<script>
import { reactive, watch } from "@vue/composition-api";
import ConfirmDialog from "../general/ConfirmDialog";
import AddUsers from "./AddUsers";
import Dialog from "../general/Dialog";
import DeleteClassroom from "./DeleteClassroom";
import LeaveClassroom from "./LeaveClassroom";
import router from "../../router";

export default {
	name: "ClassroomTile",
	components: {
		LeaveClassroom,
		DeleteClassroom,
		Dialog,
		AddUsers,
		ConfirmDialog,
	},
	props: {
		options: {
			type: Array,
		},
		tileColor: {
			type: String,
		},
		classroomData: {
			type: Object,
		},
		active: {
			type: Boolean,
			default: false,
		},
	},
	setup(props, { emit, root }) {
		const state = reactive({
			leaveClassroomDialog: false,
			deleteClassroomDialog: false,
			addUsersDialog: false,

			initialClassroomData: props.classroomData,
			classroomData: root.$clone(props.classroomData),

			hasChanges: false,
		});

		watch(
			() => state.classroomData,
			(classroomData) => {
				// console.log(deepEqual(userData, root.$store.getters['user/getCurrentUserData']))
				state.hasChanges = !root.$equal(
					classroomData,
					state.initialClassroomData
				);
			},
			{ deep: true }
		);

		async function goToClassroom(classroomId) {
			await router.push(`/classrooms/${classroomId}/forum`);
		}

		// Options (three-dots)
		function calculateAction(action, classroomId) {
			console.log("calculating action for:", classroomId);

			switch (action) {
				case "checkClassroom":
					checkClassroom(classroomId);
					break;
				case "addUsers":
					addUsers(classroomId);
					break;
				case "editClassroom":
					editClassroom(classroomId);
					break;
				case "leaveClassroom":
					leaveClassroom(classroomId);
					break;
				case "deleteClassroom":
					deleteClassroom(classroomId);
					break;
				case "shareLink":
					shareLink(classroomId);
					break;
			}
		}

		function checkClassroom() {}

		function addUsers() {
			state.addUsersDialog = true;
		}

		function editClassroom() {}

		function leaveClassroom() {
			state.leaveClassroomDialog = true;
		}

		function deleteClassroom() {
			state.deleteClassroomDialog = true;
		}

		function shareLink(classroomId) {
			// window.navigator.share({
			//   title: '',
			//   url: 'google.com'
			// })
		}

		function resetData() {
			state.addUsersDialog = false;
			state.classroomData = root.$clone(state.initialClassroomData);
		}

		function formatClassInfo(classroomData) {
			let groupsFormatting = "";
			if (classroomData.groups) {
				for (let i = 0; i < classroomData.groups.length; i++) {
					if (i < classroomData.groups.length - 1) {
						groupsFormatting += classroomData.groups[i] + ", ";
					} else {
						groupsFormatting += classroomData.groups[i];
					}
				}
			}

			if (root.$isMobileOnly || root.$route.params.classroomId) {
				return `<span style="font-weight: 500">${classroomData.subject.toUpperCase()} ${
					classroomData.groupYear
				} |
                ${
									classroomData.groups.length === 1 ? "Grupi: " : "Grupet: "
								}${groupsFormatting}</span>`;
			} else {
				return `<span style="font-weight: 500">${classroomData.subject.toUpperCase()}</span>
                <br>
                <span style="font-weight: 500">Viti: </span>${
									classroomData.groupYear
								}</span>
                <br>
                <span style="font-weight: 500">${
									classroomData.groups.length === 1 ? "Grupi: " : "Grupet: "
								}</span>${groupsFormatting}`;
			}
		}

		function log() {
			// console.log('logging...')
			console.log(state.classroomData);
			let difference = state.classroomData.teachers.filter(
				(x) => !state.initialClassroomData.teachers.includes(x)
			);
		}

		return {
			state,

			goToClassroom,

			calculateAction,
			resetData,
			formatClassInfo,

			log,
		};
	},
};
</script>

<style lang="scss" scoped>
.class-info {
	color: #ffffff;
	height: 100%;
	user-select: none;
	padding: 10px;
	display: flex;
	align-content: center;
	align-items: center;

	div.content {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}
</style>
