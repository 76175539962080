<template>
	<div>
		<div class="d-flex align-end">
			<v-spacer />
			<v-btn
				v-if="isGrantedAccess('createAny', 'classroom')"
				class="mb-4"
				color="primary"
				depressed
				@click="state.showDialog = !state.showDialog"
			>
				<v-icon left>mdi-plus</v-icon>
				Krijo klasë
			</v-btn>
		</div>

		<Dialog
			v-model="state.showDialog"
			:persistent="true"
			width="900px"
			@closeDialog="
				$refs.createClassroomForm.reset();
				state.showDialog = false;
			"
		>
			<!--  Dialog header  -->
			<template v-slot:header> Krijo një klasë të re</template>

			<!--  Content -->
			<template v-slot:content>
				<!-- Form  -->
				<v-form ref="createClassroomForm" class="mt-1" @submit.prevent="">
					<!-- Subject select -->
					<v-row class="flex-wrap">
						<v-col :cols="$isMobileOnly ? '12' : '4'">
							<v-autocomplete
								v-model="state.selectedSubject"
								:filter="autocompleteFilter"
								:items="state.subjects"
								:label="$isMobileOnly ? 'Lënda' : 'Zgjidh Lëndën'"
								:rules="state.subjectRules"
								color="primary"
								dense
								hide-details="auto"
								no-data-text="Nuk u gjet asnjë rezultat"
								outlined
							/>
						</v-col>

						<!-- Group Year select -->
						<v-col :cols="$isMobileOnly ? '12' : '3'">
							<v-select
								v-model="state.selectedGroupYear"
								:filter="autocompleteFilter"
								:items="state.groupYears"
								:label="$isMobileOnly ? 'Viti' : 'Zgjidh vitin'"
								:menu-props="{ offsetY: true }"
								:rules="state.groupYearRule"
								dense
								hide-details="auto"
								no-data-text="Nuk u gjet asnjë rezultat"
								outlined
								validate-on-blur
							></v-select>
						</v-col>

						<!--  Group select  -->
						<v-col :cols="$isMobileOnly ? '12' : '5'">
							<div class="d-flex">
								<div class="full-width">
									<v-select
										v-model="state.selectedGroup"
										:filter="autocompleteFilter"
										:items="
											state.toggleGroup === 0
												? state.groups.byChar
												: state.groups.byNum
										"
										:label="$isMobileOnly ? 'Grupi' : 'Shto grup'"
										:menu-props="{ offsetY: true }"
										:rules="state.groupRules"
										chips
										deletable-chips
										dense
										hide-details="auto"
										multiple
										no-data-text="Nuk u gjet asnjë rezultat"
										outlined
										small-chips
									></v-select>
								</div>

								<!-- Toggle for class groups (alphabetical or numeric) -->
								<v-btn-toggle
									v-model="state.toggleGroup"
									class="ml-2"
									color="primary"
									group
									mandatory
									tile
									@change="state.selectedGroup = null"
								>
									<v-btn height="32px" width="32px">
										<v-icon>mdi-alphabetical-variant</v-icon>
									</v-btn>

									<v-btn height="32px" width="32px">
										<v-icon>mdi-numeric</v-icon>
									</v-btn>
								</v-btn-toggle>
							</div>
						</v-col>

						<!-- Add teachers autocomplete -->
						<v-col cols="12">
							<AddUsers
								:maxUsers="6"
								:users.sync="state.selectedTeachers"
								role="teacher"
							/>
						</v-col>

						<!-- Add students autocomplete -->
						<v-col cols="12">
							<AddUsers
								:maxUsers="25"
								:users.sync="state.selectedStudents"
								role="student"
							/>
						</v-col>
					</v-row>
				</v-form>
			</template>

			<!-- Actions -->
			<template v-slot:actions>
				<v-spacer></v-spacer>
				<v-btn
					:class="{ 'full-width': $isMobileOnly }"
					:disabled="state.loading"
					:loading="state.loading"
					color="primary"
					@click="onSave"
					depressed
				>
					<v-icon left>mdi-check</v-icon>
					Ruaj
				</v-btn>
			</template>
		</Dialog>
	</div>
</template>

<script>
import { reactive, watch } from "@vue/composition-api";
import Dialog from "../../components/general/Dialog";
import AddUsers from "../../components/classroom/AddUsers";
import { autocompleteFilter } from "../../../../shared/utilities/general.utilities";
import FlashService from "../../services/flash.service";

export default {
	name: "CreateClassroom",
	components: { AddUsers, Dialog },
	setup(props, { root, emit, refs }) {
		const state = reactive({
			showDialog: false,
			loading: false,

			selectedSubject: null,
			selectedGroupYear: null,
			selectedGroup: null,

			subjects: [
				{
					text: "Abetare",
					value: "abetare",
				},
				// {
				//   text: "Gjuhë Shqipe",
				//   value: "gjuheShqipe"
				// },
				// {
				//   text: "Matematikë",
				//   value: "matematike"
				// },
				// {
				//   text: "Biologji",
				//   value: "biologji"
				// },
				// {
				//   text: "Dituri Natyre",
				//   value: "dituriNatyre"
				// },
				// {
				//   text: "Edukate Shoqerore",
				//   value: "edukateShoqerore"
				// }
			],
			subjectRules: [(v) => !!v || "Duhet të zgjdhni patjetër një lëndë"],

			groupYears: [
				"1",
				"2",
				"3",
				"4",
				"5",
				"6",
				"7",
				"8",
				"9",
				"10",
				"11",
				"12",
			],
			groupYearRule: [(v) => !!v || "Duhet të zgjdhni patjetër një vit"],

			groups: {
				byChar: [
					"A",
					"B",
					"C",
					"D",
					"E",
					"F",
					"G",
					"H",
					"I",
					"J",
					"K",
					"L",
					"M",
					"N",
					"O",
					"P",
					"Q",
					"R",
					"S",
					"T",
					"U",
					"V",
					"W",
					"X",
					"Y",
					"Z",
				],
				byNum: [
					1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
					21, 22, 23, 24, 25, 26,
				],
			},
			groupRules: [(v) => !!v || "Duhet të zgjdhni të paktën një grup"],
			toggleGroup: 0,

			// Add to classroom
			selectedStudents: [],
			selectedTeachers: [],
		});

		// TODO: Remove
		watch([() => state.selectedStudents, () => state.selectedTeachers], () => {
			console.log("Selected Students", state.selectedStudents);
			console.log("Selected Teachers", state.selectedTeachers);
		});

		async function onSave() {
			if (!refs["createClassroomForm"]?.validate()) return;

			state.loading = true;

			const classroomData = {
				subject: state.selectedSubject,
				groupYear: state.selectedGroupYear,
				groups: state.selectedGroup,
				teachers: state.selectedTeachers,
				students: state.selectedStudents,
			};

			try {
				await root.$store.dispatch("classrooms/createClassroom", classroomData);
			} catch (err) {
				FlashService.push({
					type: "error",
					msg: "Ka ndodhur një gabim! Klasa nuk mund të krijohej.",
				});
				return err;
			} finally {
				state.showDialog = false;
				refs["createClassroomForm"]?.reset(); // Reset form
				setTimeout(() => {
					state.loading = false;
				}, 600);
			}
		}

		return {
			state,

			autocompleteFilter,
			onSave,
		};
	},
};
</script>
