async function sleep(millis) {
	await new Promise(r => setTimeout(r, millis));
}

function debounce(func, wait, immediate) {
	let timeout;
	return function() {
		let context = this,
			args = arguments;
		let later = function() {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		let callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
}

function timeSince(date) {
	if (typeof date !== "object") {
		date = new Date(date);
	}

	let seconds = Math.floor((new Date() - date) / 1000) || 1;

	let interval = seconds / 31536000;

	// Year
	if (interval >= 2) {
		return Math.floor(interval) + " vite";
	} else if (interval > 1) {
		return Math.floor(interval) + " vit";
	}

	// Months
	interval = seconds / 2592000;
	if (interval > 1) {
		return Math.floor(interval) + " muaj";
	}

	// Days
	interval = seconds / 86400;
	if (interval > 1) {
		return Math.floor(interval) + " ditë";
	}

	// Hours
	interval = seconds / 3600;
	if (interval > 1) {
		return Math.floor(interval) + " orë";
	}

	// Minutes
	interval = seconds / 60;
	if (interval >= 2) {
		return Math.floor(interval) + " minuta";
	} else if (interval > 1) {
		return Math.floor(interval) + " minut";
	}

	// Seconds
	interval = seconds;
	if (interval >= 2) {
		return Math.floor(interval) + " sekonda";
	} else if (interval > 0) {
		return Math.floor(interval) + " sekond";
	}
}

function formatDate(date) {
	if (typeof date !== "object") {
		date = new Date(date);
	}

	return date.toLocaleString("en-GB");
}

function getInitials(fullName) {
	let rgx = new RegExp(/(\p{L}{1})\p{L}+/, "gu");

	let initials = [...fullName.matchAll(rgx)] || [];

	initials = (
		(initials.shift()?.[1] || "") + (initials.pop()?.[1] || "")
	).toUpperCase();

	return initials;
}

const toBase64 = file =>
	new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result);
		reader.onerror = error => reject(error);
	});

export { debounce, timeSince, formatDate, getInitials, toBase64 };
