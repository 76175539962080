import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VBtnToggle } from 'vuetify/lib/components/VBtnToggle';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex align-end"},[_c(VSpacer),(_vm.isGrantedAccess('createAny', 'classroom'))?_c(VBtn,{staticClass:"mb-4",attrs:{"color":"primary","depressed":""},on:{"click":function($event){_vm.state.showDialog = !_vm.state.showDialog}}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" Krijo klasë ")],1):_vm._e()],1),_c('Dialog',{attrs:{"persistent":true,"width":"900px"},on:{"closeDialog":function($event){_vm.$refs.createClassroomForm.reset();
			_vm.state.showDialog = false;}},scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v(" Krijo një klasë të re")]},proxy:true},{key:"content",fn:function(){return [_c(VForm,{ref:"createClassroomForm",staticClass:"mt-1",on:{"submit":function($event){$event.preventDefault();}}},[_c(VRow,{staticClass:"flex-wrap"},[_c(VCol,{attrs:{"cols":_vm.$isMobileOnly ? '12' : '4'}},[_c(VAutocomplete,{attrs:{"filter":_vm.autocompleteFilter,"items":_vm.state.subjects,"label":_vm.$isMobileOnly ? 'Lënda' : 'Zgjidh Lëndën',"rules":_vm.state.subjectRules,"color":"primary","dense":"","hide-details":"auto","no-data-text":"Nuk u gjet asnjë rezultat","outlined":""},model:{value:(_vm.state.selectedSubject),callback:function ($$v) {_vm.$set(_vm.state, "selectedSubject", $$v)},expression:"state.selectedSubject"}})],1),_c(VCol,{attrs:{"cols":_vm.$isMobileOnly ? '12' : '3'}},[_c(VSelect,{attrs:{"filter":_vm.autocompleteFilter,"items":_vm.state.groupYears,"label":_vm.$isMobileOnly ? 'Viti' : 'Zgjidh vitin',"menu-props":{ offsetY: true },"rules":_vm.state.groupYearRule,"dense":"","hide-details":"auto","no-data-text":"Nuk u gjet asnjë rezultat","outlined":"","validate-on-blur":""},model:{value:(_vm.state.selectedGroupYear),callback:function ($$v) {_vm.$set(_vm.state, "selectedGroupYear", $$v)},expression:"state.selectedGroupYear"}})],1),_c(VCol,{attrs:{"cols":_vm.$isMobileOnly ? '12' : '5'}},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"full-width"},[_c(VSelect,{attrs:{"filter":_vm.autocompleteFilter,"items":_vm.state.toggleGroup === 0
											? _vm.state.groups.byChar
											: _vm.state.groups.byNum,"label":_vm.$isMobileOnly ? 'Grupi' : 'Shto grup',"menu-props":{ offsetY: true },"rules":_vm.state.groupRules,"chips":"","deletable-chips":"","dense":"","hide-details":"auto","multiple":"","no-data-text":"Nuk u gjet asnjë rezultat","outlined":"","small-chips":""},model:{value:(_vm.state.selectedGroup),callback:function ($$v) {_vm.$set(_vm.state, "selectedGroup", $$v)},expression:"state.selectedGroup"}})],1),_c(VBtnToggle,{staticClass:"ml-2",attrs:{"color":"primary","group":"","mandatory":"","tile":""},on:{"change":function($event){_vm.state.selectedGroup = null}},model:{value:(_vm.state.toggleGroup),callback:function ($$v) {_vm.$set(_vm.state, "toggleGroup", $$v)},expression:"state.toggleGroup"}},[_c(VBtn,{attrs:{"height":"32px","width":"32px"}},[_c(VIcon,[_vm._v("mdi-alphabetical-variant")])],1),_c(VBtn,{attrs:{"height":"32px","width":"32px"}},[_c(VIcon,[_vm._v("mdi-numeric")])],1)],1)],1)]),_c(VCol,{attrs:{"cols":"12"}},[_c('AddUsers',{attrs:{"maxUsers":6,"users":_vm.state.selectedTeachers,"role":"teacher"},on:{"update:users":function($event){return _vm.$set(_vm.state, "selectedTeachers", $event)}}})],1),_c(VCol,{attrs:{"cols":"12"}},[_c('AddUsers',{attrs:{"maxUsers":25,"users":_vm.state.selectedStudents,"role":"student"},on:{"update:users":function($event){return _vm.$set(_vm.state, "selectedStudents", $event)}}})],1)],1)],1)]},proxy:true},{key:"actions",fn:function(){return [_c(VSpacer),_c(VBtn,{class:{ 'full-width': _vm.$isMobileOnly },attrs:{"disabled":_vm.state.loading,"loading":_vm.state.loading,"color":"primary","depressed":""},on:{"click":_vm.onSave}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-check")]),_vm._v(" Ruaj ")],1)]},proxy:true}]),model:{value:(_vm.state.showDialog),callback:function ($$v) {_vm.$set(_vm.state, "showDialog", $$v)},expression:"state.showDialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }