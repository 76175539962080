<template>
	<div class="d-flex full-width">
		<!-- Classrooms Panel -->
		<div
			v-if="!$isMobileOnly || !($isMobileOnly && $route.params.classroomId)"
			:style="$route.params.classroomId ? 'width: 20%;' : 'width: 100%'"
			style="overflow-y: auto; min-width: 300px"
			class="pa-4"
		>
			<!-- Button and a dialog to add a new classroom -->
			<CreateClassroom />

			<!-- Classrooms List -->
			<v-expansion-panels v-model="state.panel" accordion multiple mandatory>
				<v-expansion-panel>
					<v-expansion-panel-header class="expansion-header" color="background">
						Kryesoret
					</v-expansion-panel-header>
					<v-expansion-panel-content
						color="background"
						:class="
							$isMobileOnly || $route.params.classroomId
								? 'mobile-wrap'
								: 'desktop-wrap'
						"
					>
						<div
							v-if="!state.loading && state.classrooms.length === 0"
							class="ml-6"
						>
							Nuk ka asnjë klasë për të shfaqur
						</div>
						<!--						<v-skeleton-loader-->
						<!--							v-for="i in 8"-->
						<!--							v-if="state.loading"-->
						<!--							:key="`skeleton-${i}`"-->
						<!--							class="ma-4"-->
						<!--							height="150"-->
						<!--							type="card"-->
						<!--							width="120"-->
						<!--						/>-->
						<ClassroomTile
							v-for="(classroom, index) in state.classrooms"
							:key="`classroom-${classroom.id}`"
							:classroomData="classroom"
							:options="state.classOptions"
							:tile-color="state.colorArray[index]"
							:active="$route.params.classroomId === classroom.id"
							:class="
								$isMobileOnly || $route.params.classroomId
									? 'mx-4 my-2'
									: 'ma-4'
							"
						/>
					</v-expansion-panel-content>
				</v-expansion-panel>
			</v-expansion-panels>
		</div>

		<v-divider vertical class="mx-0 px-0"></v-divider>

		<!-- Selected Classroom-->
		<router-view
			name="classroom"
			:style="$isMobileOnly ? 'width: 100%;' : 'width: 80%;'"
			style="overflow-y: hidden"
		>
		</router-view>
	</div>
</template>

<script>
import { computed, reactive } from "@vue/composition-api";
import ClassroomTile from "../components/classroom/ClassroomTile";
import Dialog from "../components/general/Dialog";
import CreateClassroom from "../components/classroom/CreateClassroom";
import ClassroomService from "../services/classroom.service";

export default {
	name: "ClassroomControllers",
	components: { CreateClassroom, Dialog, ClassroomTile },
	setup(props, { root, refs }) {
		const state = reactive({
			classrooms: computed(() => root.$clone(ClassroomService.getClassrooms())),
			loading: false,

			panel: [0],
			colorArray: [
				"#d96a44",
				"#de9176",
				"#c786c7",
				"#b8ab65",
				"#00B3E6",
				"#d7a830",
				"#6582ce",
				"#bbbb75",
				"#7dc67d",
				"#B34D4D",
				"#92b636",
				"#1dad30",
				"#d9a0a0",
				"#5e76a5",
				"#66991A",
				"#e086ca",
				"#9bbd23",
				"#de4e7e",
				"#e8614c",
				"#33FFCC",
				"#81c165",
				"#B366CC",
				"#74a827",
				"#bf3d08",
				"#CC80CC",
				"#66664D",
				"#991AFF",
				"#E666FF",
				"#62b1e8",
				"#1AB399",
				"#E666B3",
				"#33991A",
				"#CC9999",
				"#B3B31A",
				"#00E680",
				"#4D8066",
				"#809980",
				"#E6FF80",
				"#1AFF33",
				"#999933",
				"#FF3380",
				"#CCCC00",
				"#66E64D",
				"#4D80CC",
				"#9900B3",
				"#E64D66",
				"#4DB380",
				"#FF4D4D",
				"#99E6E6",
				"#6666FF",
			],
			classOptions: [
				{
					title: "Kontrollo klasën",
					icon: "mdi-cog",
					action: "checkClassroom",
					roleAction: "updateOwn",
				},
				{
					title: "Shto nxënës/mësues",
					icon: "mdi-account-multiple-plus-outline",
					action: "addUsers",
					roleAction: "updateOwn",
				},
				{
					title: "Përditëso klasën",
					icon: "mdi-pencil",
					action: "editClassroom",
					roleAction: "updateOwn",
				},
				{
					title: "Shpërndaj linkun",
					icon: "mdi-link",
					action: "shareLink",
					roleAction: "readOwn",
				},
				{
					title: "Largohu nga klasa",
					icon: "mdi-account-arrow-left-outline",
					textColor: "#ff0000",
					action: "leaveClassroom",
					roleAction: "readOwn",
				},
				{
					title: "Fshij klasën",
					icon: "mdi-trash-can-outline",
					textColor: "white",
					bgColor: "#c90000",
					action: "deleteClassroom",
					roleAction: "deleteOwn",
				},
			],
		});

		function log() {
			console.log("logging...");
		}

		return {
			state,

			log,
		};
	},
};
</script>

<style lang="scss" scoped>
.v-expansion-panel-content::v-deep {
	.v-expansion-panel-content__wrap {
		display: flex;
		flex-direction: column;
		padding: 0 !important;
		margin: 10px 0;
	}
}

.v-expansion-panel-content.desktop-wrap::v-deep {
	.v-expansion-panel-content__wrap {
		flex-direction: row !important;
		flex-wrap: wrap !important;
	}
}

.expansion-header {
	color: var(--v-text-base);
	font-weight: 500;
	font-size: 1.4rem;
	height: 64px !important;

	&::v-deep i {
		color: var(--v-text-base) !important;
	}
}
</style>
