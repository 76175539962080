<template>
	<ConfirmDialog
		v-model="showDialog"
		type="error"
		@closeDialog="showDialog = false"
	>
		<template v-slot:header>
			Kujdes!
		</template>

		<template v-slot:content>
			<div class="text-center">
				Jeni të sigurt që dëshironi të fshini klasën?<br />
				<em class="error--text">*Ky veprim nuk mund të kthehet pas</em>
			</div>
		</template>

		<template v-slot:actions>
			<v-spacer />
			<v-btn
				:disabled="state.loading"
				:loading="state.loading"
				color="error"
				@click="deleteClassroom(classroomId)"
			>
				Fshij
			</v-btn>
			<!--        <v-spacer/>-->
		</template>
	</ConfirmDialog>
</template>

<script>
import ConfirmDialog from "../general/ConfirmDialog";
import { computed, reactive } from "@vue/composition-api";

export default {
	name: "DeleteClassroom",
	components: { ConfirmDialog },
	props: ["value", "classroomId"],
	setup(props, { root, emit }) {
		const state = reactive({
			loading: false
		});

		const showDialog = computed({
			get: () => props.value,
			set: value => {
				emit("input", value);
			}
		});

		async function deleteClassroom(classroomId) {
			state.loading = true;

			await root.$store.dispatch("classrooms/deleteClassroom", classroomId);

			showDialog.value = false;

			setTimeout(() => {
				state.loading = false;
			}, 600);
		}

		return {
			state,
			showDialog,

			deleteClassroom
		};
	}
};
</script>

<style scoped></style>
