import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import Ripple from 'vuetify/lib/directives/ripple';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VSheet,{directives:[{def: Ripple,name:"ripple",rawName:"v-ripple"}],staticStyle:{"position":"relative","cursor":"pointer"},attrs:{"color":_vm.tileColor,"elevation":_vm.active ? 5 : 0,"height":_vm.$isMobileOnly || _vm.$route.params.classroomId ? 46 : 150,"width":_vm.$isMobileOnly || _vm.$route.params.classroomId ? '100%' : 120,"rounded":""},on:{"click":function($event){return _vm.goToClassroom(_vm.classroomData.id)}}},[_c(VMenu,{attrs:{"offset-y":"","rounded":"lg","z-index":"999"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({style:(_vm.$isMobileOnly || _vm.$route.params.classroomId
							? 'right: 4px; top: 50%; transform: translateY(-50%)'
							: 'right: 4px; top: -2px;'),attrs:{"absolute":"","depressed":"","icon":"","plain":"","height":"28px","ripple":false,"width":"28px"},on:{"touchstart":function($event){$event.stopPropagation();},"mousedown":function($event){$event.stopPropagation();}}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"color":"white"}},[_vm._v(" "+_vm._s(_vm.$isMobileOnly || _vm.$route.params.classroomId ? "mdi-dots-vertical" : "mdi-dots-horizontal")+" ")])],1)]}}])},[_c(VList,{staticClass:"pa-0"},_vm._l((_vm.options),function(option,index){return (_vm.isGrantedAccess(option.roleAction, 'classroom'))?_c(VListItem,{key:((_vm.classroomData.id) + "-option-" + index),staticClass:"px-2",staticStyle:{"min-height":"40px"},style:(("background-color: " + (option.bgColor))),attrs:{"color":option.color,"link":""},on:{"click":function($event){return _vm.calculateAction(option.action, _vm.classroomData.id)}}},[_c(VListItemTitle,{staticClass:"d-flex align-center"},[_c(VIcon,{staticClass:"mr-2",attrs:{"color":option.textColor}},[_vm._v(_vm._s(option.icon))]),_c('span',{style:(("color: " + (option.textColor)))},[_vm._v(_vm._s(option.title))])],1)],1):_vm._e()}),1)],1),_c('div',{staticClass:"class-info"},[_c('div',{staticClass:"content",style:(_vm.$isMobileOnly || _vm.$route.params.classroomId
						? 'width: 90%'
						: 'width: 100%'),domProps:{"innerHTML":_vm._s(_vm.formatClassInfo(_vm.classroomData))}})])],1),_c('Dialog',{attrs:{"persistent":true,"width":"900px"},on:{"closeDialog":_vm.resetData},scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v(" Shto nxënës/mësues ")]},proxy:true},{key:"content",fn:function(){return [_c(VForm,{ref:"addUsersForm",on:{"submit":function($event){$event.preventDefault();}}},[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c('AddUsers',{attrs:{"maxUsers":6,"users":_vm.state.classroomData.teachers,"role":"teacher"},on:{"update:users":function($event){return _vm.$set(_vm.state.classroomData, "teachers", $event)}}})],1),_c(VCol,{attrs:{"cols":"12"}},[_c('AddUsers',{attrs:{"maxUsers":25,"users":_vm.state.classroomData.students,"role":"student"},on:{"update:users":function($event){return _vm.$set(_vm.state.classroomData, "students", $event)}}})],1)],1)],1)]},proxy:true},{key:"actions",fn:function(){return [_c(VSpacer),_c(VBtn,{class:{ 'full-width': _vm.$isMobileOnly },attrs:{"disabled":_vm.state.loading || !_vm.state.hasChanges,"loading":_vm.state.loading,"color":"primary"},on:{"click":_vm.log}},[_vm._v(" Ruaj ")])]},proxy:true}]),model:{value:(_vm.state.addUsersDialog),callback:function ($$v) {_vm.$set(_vm.state, "addUsersDialog", $$v)},expression:"state.addUsersDialog"}}),_c('LeaveClassroom',{attrs:{"classroom-id":_vm.classroomData.id},model:{value:(_vm.state.leaveClassroomDialog),callback:function ($$v) {_vm.$set(_vm.state, "leaveClassroomDialog", $$v)},expression:"state.leaveClassroomDialog"}}),_c('DeleteClassroom',{attrs:{"classroom-id":_vm.classroomData.id},model:{value:(_vm.state.deleteClassroomDialog),callback:function ($$v) {_vm.$set(_vm.state, "deleteClassroomDialog", $$v)},expression:"state.deleteClassroomDialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }