import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VChip } from 'vuetify/lib/components/VChip';
import { VImg } from 'vuetify/lib/components/VImg';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VAutocomplete,{attrs:{"counter":_vm.maxUsers,"counter-value":function () { return _vm.selectedUsers.length; },"disabled":_vm.state.isFetching,"filter":_vm.filterByEmailAndName,"items":_vm.state.users,"label":("Shto " + (_vm.userRoleFormat())),"loading":_vm.state.isLoading || _vm.state.isFetching,"menu-props":{ closeOnContentClick: _vm.$isMobile },"no-data-text":_vm.state.isLoading
			? 'Po ngarkohet. Ju lutem prisni...'
			: 'Nuk u gjet asnjë rezultat',"placeholder":("Shkruani emër ose e-mail të " + (_vm.userRoleFormat()) + "it"),"rules":_vm.state.maxItemsRules,"search-input":_vm.state.search,"chips":"","color":"primary","hide-details":"auto","hide-selected":"","item-text":"fullName","item-value":"id","multiple":"","outlined":""},on:{"update:searchInput":function($event){return _vm.$set(_vm.state, "search", $event)},"update:search-input":[function($event){return _vm.$set(_vm.state, "search", $event)},function($event){_vm.state.search.length > 1 ? (_vm.state.isLoading = true) : ''}],"change":function($event){_vm.state.search = null}},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_c(VChip,_vm._b({attrs:{"input-value":data.selected,"close":"","color":"chip","text-color":"white"},on:{"click":data.select,"click:close":function($event){return _vm.removeUser(data.item)}}},'v-chip',data.attrs,false),[_c(VAvatar,{staticClass:"justify-center",attrs:{"left":"","color":"primary"}},[_c(VImg,{attrs:{"src":data.item.avatar || _vm.$defaultAvatar}})],1),_vm._v(" "+_vm._s(data.item.fullName)+" ")],1)]}},{key:"item",fn:function(data){return [(typeof data.item !== 'object')?[_c(VListItemContent,{domProps:{"textContent":_vm._s(data.item)}})]:[_c(VListItemAvatar,{staticClass:"justify-center"},[_c('img',{attrs:{"alt":((data.item.fullName) + " Profile Photo"),"src":data.item.avatar || _vm.$defaultAvatar}})]),_c(VListItemContent,[_c(VListItemTitle,{domProps:{"innerHTML":_vm._s(data.item.fullName)}}),_c(VListItemSubtitle,{domProps:{"innerHTML":_vm._s(data.item.email)}})],1)]]}}]),model:{value:(_vm.selectedUsers),callback:function ($$v) {_vm.selectedUsers=$$v},expression:"selectedUsers"}})}
var staticRenderFns = []

export { render, staticRenderFns }