import store from "../store";

const ClassroomService = {
	getClassrooms() {
		return store.getters["classrooms/getClassrooms"];
	},
	getClassroomById(classroomId) {
		return store.getters["classrooms/getClassroomById"](classroomId);
	},
	getClassroomStudents(classroomId) {
		return store.getters["classrooms/getClassroomById"](classroomId)?.students;
	},
};

export default ClassroomService;
