function lowercaseAndNormalize(query) {
  return query
    .toLocaleLowerCase()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "");
}

function autocompleteFilter(item, queryText, itemText) {
  return (
    lowercaseAndNormalize(itemText).indexOf(lowercaseAndNormalize(queryText)) >
    -1
  );
}

const groupByKey = (list, key, { omitKey = false }) =>
  list.reduce(
    (hash, { [key]: value, ...rest }) => ({
      ...hash,
      [value]: (hash[value] || []).concat(
        omitKey ? { ...rest } : { [key]: value, ...rest }
      ),
    }),
    {}
  );

export { lowercaseAndNormalize, autocompleteFilter, groupByKey };
