<template>
	<v-autocomplete
		v-model="selectedUsers"
		:counter="maxUsers"
		:counter-value="() => selectedUsers.length"
		:disabled="state.isFetching"
		:filter="filterByEmailAndName"
		:items="state.users"
		:label="`Shto ${userRoleFormat()}`"
		:loading="state.isLoading || state.isFetching"
		:menu-props="{ closeOnContentClick: $isMobile }"
		:no-data-text="
			state.isLoading
				? 'Po ngarkohet. Ju lutem prisni...'
				: 'Nuk u gjet asnjë rezultat'
		"
		:placeholder="`Shkruani emër ose e-mail të ${userRoleFormat()}it`"
		:rules="state.maxItemsRules"
		:search-input.sync="state.search"
		chips
		color="primary"
		hide-details="auto"
		hide-selected
		item-text="fullName"
		item-value="id"
		multiple
		outlined
		@change="state.search = null"
		@update:search-input="
			state.search.length > 1 ? (state.isLoading = true) : ''
		"
	>
		<template v-slot:selection="data">
			<v-chip
				v-bind="data.attrs"
				:input-value="data.selected"
				close
				color="chip"
				text-color="white"
				@click="data.select"
				@click:close="removeUser(data.item)"
			>
				<v-avatar left color="primary" class="justify-center">
					<v-img :src="data.item.avatar || $defaultAvatar"></v-img>
				</v-avatar>
				{{ data.item.fullName }}
			</v-chip>
		</template>
		<template v-slot:item="data">
			<template v-if="typeof data.item !== 'object'">
				<v-list-item-content v-text="data.item"></v-list-item-content>
			</template>
			<template v-else>
				<v-list-item-avatar class="justify-center">
					<img
						:alt="`${data.item.fullName} Profile Photo`"
						:src="data.item.avatar || $defaultAvatar"
					/>
				</v-list-item-avatar>
				<v-list-item-content>
					<v-list-item-title v-html="data.item.fullName"></v-list-item-title>
					<v-list-item-subtitle v-html="data.item.email"></v-list-item-subtitle>
				</v-list-item-content>
			</template>
		</template>
	</v-autocomplete>
</template>

<script>
import { computed, onMounted, reactive, watch } from "@vue/composition-api";
import { debounce } from "../../utilities/general.utilities";
import { lowercaseAndNormalize } from "../../../../shared/utilities/general.utilities";

export default {
	name: "AddUsers",
	props: {
		role: {
			type: String,
			default: "student",
		},
		users: {
			type: Array,
			default: [],
		},
		maxUsers: {
			type: Number,
			default: 50,
		},
	},
	setup(props, { emit, refs, root }) {
		const state = reactive({
			users: [],
			search: null,
			isLoading: false,
			isFetching: false,

			maxItemsRules: [
				(v) =>
					v?.length <= props.maxUsers ||
					`Nuk mund të shtoni më shumë se ${
						props.maxUsers
					} ${userRoleFormat()}.`,
			],
		});

		const selectedUsers = computed({
			get: () => props.users,
			set: (value) => {
				emit("update:users", value);
			},
		});

		onMounted(async () => {
			if (selectedUsers?.value.length > 0) {
				state.isFetching = true;
				await searchUsers(selectedUsers.value, props.role);
				state.isFetching = false;
			}
		});

		//  Watch search input
		watch(
			() => state.search,
			debounce(async (val) => {
				if (state.search !== null && state.search.length > 1) {
					state.isLoading = true;
					await searchUsers(val, props.role);
					state.isLoading = false;
				} else {
					state.isLoading = false;
				}
			}, 600)
		);

		// Functions
		async function searchUsers(query, role) {
			const foundUsers = await root.$store.dispatch("user/searchUsers", {
				query,
				role,
			});
			console.log("foundUsers", foundUsers);

			if (foundUsers.length > 0) {
				for (let foundUser of foundUsers) {
					state.users.push(foundUser);
				}
			}
		}

		function removeUser(user) {
			console.log({ user });
			const index = selectedUsers.value.indexOf(user.id);
			if (index > -1) selectedUsers.value.splice(index, 1);
		}

		function filterByEmailAndName(item, queryText, itemText) {
			queryText = queryText.trim().toLowerCase();
			itemText = itemText.trim().toLowerCase();

			return (
				lowercaseAndNormalize(item.email).indexOf(
					lowercaseAndNormalize(queryText)
				) > -1 ||
				lowercaseAndNormalize(itemText).indexOf(
					lowercaseAndNormalize(queryText)
				) > -1
			);
		}

		function userRoleFormat() {
			if (props.role === "student") {
				return "nxënës";
			}
			if (props.role === "teacher") {
				return "mësues";
			}
		}

		function log() {
			console.log("test");
		}

		return {
			state,

			selectedUsers,

			filterByEmailAndName,
			removeUser,
			userRoleFormat,

			log,
		};
	},
};
</script>

<style lang="scss" scoped></style>
